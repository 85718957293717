import { createContext, useContext } from 'react'

import {
  ConsentState,
  CookieService,
  KlaroWrapper,
} from '@Components/helper/klaro'

export type GlobalContext = {
  isGlobalContextInitialized: boolean
  klaro: KlaroWrapper
  consent: ConsentState
  setConsent: (service: CookieService, state: boolean) => void
}

export const QossmicGlobalContext = createContext<GlobalContext>({
  isGlobalContextInitialized: false,
  klaro: new KlaroWrapper(),
  consent: {
    [CookieService.essential]: false,
    [CookieService.marketing]: false,
    [CookieService.youtube]: false,
  },
  setConsent: (service: CookieService, state: boolean) => {
    return
  },
})

export const useGlobalContext = () => useContext(QossmicGlobalContext)
