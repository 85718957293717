import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { QossmicGlobalContext } from '@Components/context/GlobalContext'
import {
  consentGrantedAdStorage,
  gtmVirtualPageView,
} from '@Components/helper/googleTagManager'
import { useKlaro } from '@Components/helper/klaro'

import { FaktFont } from '@Assets/styles/fonts'
import '@Assets/styles/styles.scss'
import { defaultTheme } from '@Assets/styles/theme/theme'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [klaro, consent, setConsent] = useKlaro()

  useEffect(() => {
    gtmVirtualPageView(klaro, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pageTypeName: pageProps.page || null,
      url: router.pathname,
    })
    consentGrantedAdStorage(klaro)
  })
  return (
    <QossmicGlobalContext.Provider
      value={{
        isGlobalContextInitialized: true,
        klaro: klaro,
        consent: consent,
        setConsent: setConsent,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <style jsx global>{`
          :root,
          * {
            font-family: ${FaktFont.style.fontFamily};
          }
        `}</style>
        <Component {...pageProps} />
      </ThemeProvider>
    </QossmicGlobalContext.Provider>
  )
}

export default appWithTranslation(MyApp)
