import { CookieService, KlaroWrapper } from '@Components/helper/klaro'

function gtag() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments)
}

export const gtmVirtualPageView = (klaro: KlaroWrapper, rest: object) => {
  if (!klaro.isInitialized() || !klaro.hasConsent(CookieService.marketing)) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer?.push({
    event: 'VirtualPageView',
    ...rest,
  })
}
export const consentGrantedAdStorage = (klaro: KlaroWrapper) => {
  if (!klaro.isInitialized() || !klaro.hasConsent(CookieService.marketing)) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
  })
}
