import { AppTheme } from 'styled-components'

import { FaktFont } from '../fonts'

export const devices = {
  smartphone: 576,
  mobile: 768,
  tablet: 1024,
  desktop: 1200,
}

export const breakpointsMasonry = {
  default: 3,
  1200: 3,
  1024: 2,
  768: 1,
}

export const defaultTheme: AppTheme = {
  key: 'default',
  borderRadius: {
    button: '24px',
    icon: '50%',
    box: '10px',
    input: '26px',
  },
  device: {
    smartphone: '576px',
    mobile: '768px',
    tablet: '1024px',
    desktop: '1200px',
  },
  row: {
    // TODO: rename "row" to "section"
    maxWidth: '1440px',
    padding: '4.375rem',
  },
  font: {
    primaryFamily: FaktFont.style.fontFamily,
    headline: {
      one: {
        mobile: {
          fontSize: '37px',
          fontWeight: 350,
          lineHeight: '49.28px',
          letterSpacing: 'normal',
        },
        desktop: {
          fontSize: '50px',
          fontWeight: 350,
          lineHeight: '58px',
          letterSpacing: 'normal',
        },
      },
      two: {
        mobile: {
          fontSize: '30px',
          fontWeight: 350,
          lineHeight: '36px',
          letterSpacing: 'normal',
        },
        desktop: {
          fontSize: '38px',
          fontWeight: 350,
          lineHeight: '44px',
          letterSpacing: 'normal',
        },
      },
      three: {
        mobile: {
          fontSize: '28px',
          fontWeight: 350,
          lineHeight: '34px',
          letterSpacing: 'normal',
        },
        desktop: {
          fontSize: '32px',
          fontWeight: 350,
          lineHeight: '38px',
          letterSpacing: 'normal',
        },
      },
      four: {
        mobile: {
          fontSize: '24px',
          fontWeight: 350,
          lineHeight: '30px',
          letterSpacing: 'normal',
        },
        desktop: {
          fontSize: '30px',
          fontWeight: 350,
          lineHeight: '36px',
          letterSpacing: 'normal',
        },
      },
      five: {
        mobile: {
          fontSize: '14px',
          fontWeight: 350,
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
        desktop: {
          fontSize: '14px',
          fontWeight: 350,
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
      },
    },
    text: {
      eyebrow: {
        normal: {
          mobile: {
            fontSize: '26px',
            fontWeight: 350,
            lineHeight: '35px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '26px',
            fontWeight: 350,
            lineHeight: '35px',
            letterSpacing: 'normal',
          },
        },
        small: {
          mobile: {
            fontSize: '22px',
            fontWeight: 350,
            lineHeight: '29px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '22px',
            fontWeight: 350,
            lineHeight: '29px',
            letterSpacing: 'normal',
          },
        },
      },
      normal: {
        intro: {
          mobile: {
            fontSize: '24px',
            fontWeight: 350,
            lineHeight: '36px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '26px',
            fontWeight: 350,
            lineHeight: '38px',
            letterSpacing: 'normal',
          },
        },
        normal: {
          mobile: {
            fontSize: '20px',
            fontWeight: 350,
            lineHeight: '28px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '20px',
            fontWeight: 350,
            lineHeight: '28px',
            letterSpacing: 'normal',
          },
        },
        large: {
          mobile: {
            fontSize: '22px',
            fontWeight: 350,
            lineHeight: '30px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '22px',
            fontWeight: 350,
            lineHeight: '30px',
            letterSpacing: 'normal',
          },
        },
        medium: {
          mobile: {
            fontSize: '18px',
            fontWeight: 350,
            lineHeight: '26px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '18px',
            fontWeight: 350,
            lineHeight: '26px',
            letterSpacing: 'normal',
          },
        },
        small: {
          mobile: {
            fontSize: '16px',
            fontWeight: 350,
            lineHeight: '24px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '16px',
            fontWeight: 350,
            lineHeight: '24px',
            letterSpacing: 'normal',
          },
        },
        mini: {
          mobile: {
            fontSize: '12px',
            fontWeight: 350,
            lineHeight: '20px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '12px',
            fontWeight: 350,
            lineHeight: '20px',
            letterSpacing: 'normal',
          },
        },
      },
      quote: {
        normal: {
          mobile: {
            fontSize: '32px',
            fontWeight: 350,
            lineHeight: '42px',
            letterSpacing: 'normal',
          },
          desktop: {
            fontSize: '40px',
            fontWeight: 350,
            lineHeight: '48px',
            letterSpacing: 'normal',
          },
        },
      },
    },
  },
  gradient: {
    inputBorder: 'linear-gradient(90deg, #6ca5fb 0%, #db00ff 100%)',
    main: 'linear-gradient(180deg, #F1FCFF 0%, #FFFFFF 53.65%)',
    effect:
      'radial-gradient(50% 50% at 50% 50%, rgba(251, 230, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  margin: {
    mini: '0.325rem',
    small: '0.625rem',
    regular: '1rem',
    half: '2rem',
    default: '2.5rem',
    double: '3.75rem',
    triple: '5rem',
  },
  padding: {
    mini: '0.625rem',
    regular: '1rem',
    small: '1.25rem',
    half: '2.5rem',
    default: '3.75rem',
    double: '7.5rem',
  },
  shadow:
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  palette: {
    background: {
      header: '#f1fcff',
      footer: '#1F2937',
    },
    badge: {
      primary: {
        background: '#9000A8',
        text: '#FFFFFF',
      },
    },
    form: {
      inputBorder: '#D1D5DB',
      hoverBorder: '#667085',
      activeBackgrounnd: '#F2F2F2',
    },
    // TODO: please also update colors in `assets/styles/variables.scss`
    button: {
      primary: {
        background: '#c2067b',
        border: '#c2067b',
        text: '#FFFFFF',
      },
      secondary: {
        background: '#FFFFFF',
        border: '#D9D9D9',
        text: '#111111',
      },
      danger: {
        background: '#E22F2F',
        border: 'none',
        text: '#FFFFFF',
      },
    },
    iconButton: {
      primary: {
        background: '#c2067b',
        border: '#c2067b',
        icon: '#FFFFFF',
      },
      secondary: {
        background: 'transparent',
        border: '#c2067b',
        icon: '#c2067b',
      },
    },
    card: {
      background: '#FFFFFF',
      border: '#E5E7EB',
    },
    default: {
      primary: '#c2067b',
      primaryDark: '#80004F',
      blueMiddle: '#243059',
      blueDeep: '#060E2B',
      blueDark: '#040A1F',
      white: '#ffffff',
      black: '#000000',
      greyDark: '#2F2F2F',
      greyMiddle: '#868686',
      greyMedium: '#474747',
      greyLight: '#CDCDCD',
      greyUltralight: '#F1F1F1',
      error: '#E22F2F',
      success: '#00ff00',
      warning: '#ffff00',
    },
    menu: {
      highlight: '#DBEAFE',
    },
    icon: {
      background: '#E5E7EB',
    },
    table: {
      row: {
        divider: '#F2F2F2',
        highlight: '#E9FFCD',
        odd: 'none',
        even: '#FAFAFA',
        winner: '#FFC87C',
      },
    },
    divider: {
      default: '#313B4A',
      medium: '#D9D9D9',
      light: '#F2F2F2',
    },
    message: {
      error: {
        text: '#B14646',
        background: '#FEF2F2',
      },
      warning: {
        text: '#8E6C0F',
        background: '#FBEECA',
      },
      success: {
        text: '#507B33',
        background: '#D8EACC',
      },
      info: {
        text: '#215489',
        background: '#C5DBF2',
      },
    },
    text: {
      topbar: '#3F455A',
      primary: '#101423',
      secondary: '#444444',
      tertiary: '#667085',
      link: '#2650BC',
      footer: {
        normal: '#6b7280',
        hover: '#9ca3af',
      },
    },
  },
}
