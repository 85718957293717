import { useEffect, useState } from 'react'

export enum CookieService {
  essential = 'essential',
  marketing = 'marketing',
  youtube = 'youtube',
}

// By default, Klaro will load the config from  a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
const klaroConfig = {
  // With the 0.7.0 release we introduce a 'version' paramter that will make
  // it easier for us to keep configuration files backwards-compatible in the future.
  version: 1,

  // You can customize the ID of the DIV element that Klaro will create
  // when starting up. If undefined, Klaro will use 'klaro'.
  elementID: 'cookie-banner',

  // You can override CSS style variables here. For IE11, Klaro will
  // dynamically inject the variables into the CSS. If you still consider
  // supporting IE9-10 (which you probably shouldn't) you need to use Klaro
  // with an external stylesheet as the dynamic replacement won't work there.

  // Setting this to true will keep Klaro from automatically loading itself
  // when the page is being loaded.
  noAutoLoad: false,

  // Setting this to true will render the descriptions of the consent
  // modal and consent notice are HTML. Use with care.
  htmlTexts: false,

  // Setting 'embedded' to true will render the Klaro modal and notice without
  // the modal background, allowing you to e.g. embed them into a specific element
  // of your website, such as your privacy notice.
  embedded: false,

  // You can group services by their purpose in the modal. This is advisable
  // if you have a large number of services. Users can then enable or disable
  // entire groups of services instead of having to enable or disable every service.
  groupByPurpose: false,

  // How Klaro should store the user's preferences. It can be either 'cookie'
  // (the default) or 'localStorage'.
  storageMethod: 'cookie',

  // You can customize the name of the cookie that Klaro uses for storing
  // user consent decisions. If undefined, Klaro will use 'klaro'.
  cookieName: 'qossmic_consent',

  // You can also set a custom expiration time for the Klaro cookie.
  // By default, it will expire after 120 days.
  cookieExpiresAfterDays: 30,

  // You can change to cookie domain for the consent manager itself.
  // Use this if you want to get consent once for multiple matching domains.
  // If undefined, Klaro will use the current domain.
  cookieDomain: 'qossmic.com',

  // You can change to cookie path for the consent manager itself.
  // Use this to restrict the cookie visibility to a specific path.
  // If undefined, Klaro will use '/' as cookie path.
  //cookiePath: '/',

  // Defines the default state for services (true=enabled by default).
  default: false,

  // If "mustConsent" is set to true, Klaro will directly display the consent
  // manager modal and not allow the user to close it before having actively
  // consented or declines the use of third-party services.
  mustConsent: false,

  // Show "accept all" to accept all services instead of "ok" that only accepts
  // required and "default: true" services
  acceptAll: true,

  // replace "decline" with cookie manager modal
  hideDeclineAll: false,

  // hide "learnMore" link
  hideLearnMore: false,

  // show cookie notice as modal
  noticeAsModal: false,

  // You can also remove the 'Realized with Klaro!' text in the consent modal.
  // Please don't do this! We provide Klaro as a free open source tool.
  // Placing a link to our website helps us spread the word about it,
  // which ultimately enables us to make Klaro! better for everyone.
  // So please be fair and keep the link enabled. Thanks :)
  disablePoweredBy: true,

  // you can specify an additional class (or classes) that will be added to the Klaro `div`
  //additionalClass: 'my-klaro',

  // You can define the UI language directly here. If undefined, Klaro will
  // use the value given in the global "lang" variable. If that does
  // not exist, it will use the value given in the "lang" attribute of your
  // HTML tag. If that also doesn't exist, it will use 'en'.
  //lang: 'en',

  // You can overwrite existing translations and add translations for your
  // service descriptions and purposes. See `src/translations/` for a full
  // list of translations that can be overwritten:
  // https://github.com/KIProtect/klaro/tree/master/src/translations

  // Example config that shows how to overwrite translations:
  // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
  translations: {
    // translationsed defined under the 'zz' language code act as default
    // translations.
    zz: {
      privacyPolicyUrl: '/datenschutzerklaerung',
    },
    // If you erase the "consentModal" translations, Klaro will use the
    // bundled translations.
    de: {
      privacyPolicyUrl: '/datenschutzerklaerung',
      consentModal: {
        title: ' ',
        description: ' ',
      },
      consentNotice: {
        title: 'Dies ist der Titel des Zustimmungs-Dialogs',
        description:
          'Cookies sind für die korrekte Funktionsweise einer Website wichtig. ' +
          'Um Ihnen eine angenehmere Erfahrung zu bieten, nutzen wir Cookies zum Speichern Ihrer Anmeldedaten, ' +
          'um für sichere Anmeldung zu sorgen, um statistische Daten zur Optimierung der Website-Funktionen zu erheben und um Ihnen Inhalt bereitzustellen, ' +
          'der auf Ihre Interessen zugeschnitten ist.',
        learnMore: 'Cookie-Einstellungen',
        changeDescription:
          'Sie können diese Einstellungen jederzeit nachträglich über den Button unten links widerrufen.',
      },
      privacyPolicy: {
        text: 'Unsere {privacyPolicy}.',
        name: 'Datenschutzerklärung',
      },
      poweredBy: 'Konfiguration ansehen',
      decline: 'Alle ablehnen',
      ok: 'Alle Cookies akzeptieren!',
      acceptSelected: 'Auswahl akzeptieren',
      acceptAll: 'Alle akzeptieren',
      save: 'Speichern',
      service: {
        purpose: ' ',
        disableAll: {
          title: 'Alle optionalen aktivieren/deaktivieren',
          description: ' ',
        },
        required: {
          title: ' ',
        },
      },
    },
  },

  // This is a list of third-party services that Klaro will manage for you.
  services: [
    {
      name: CookieService.essential,
      default: true,
      translations: {
        de: {
          title: 'Unbedingt erforderliche Cookies',
          description:
            'Diese Cookies sind zur Funktion der Website erforderlich und können in Ihren Systemen nicht deaktiviert werden. In der Regel werden diese Cookies nur als Reaktion auf von Ihnen getätigte Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen, dem Anmelden oder dem Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies benachrichtigt werden. Einige Bereiche der Website funktionieren dann aber nicht. Diese Cookies speichern keine personenbezogenen Daten.',
        },
      },
      purposes: [CookieService.essential],
      required: true,
    },
    {
      name: CookieService.marketing,
      default: false,
      translations: {
        zz: {
          title: 'Cookies für Marketingzwecke',
          description:
            'Diese Cookies können über unsere Website von unseren Werbepartnern gesetzt werden. Sie können von diesen Unternehmen verwendet werden, um ein Profil Ihrer Interessen zu erstellen und Ihnen relevante Anzeigen auf anderen Websites zu zeigen. Sie speichern nicht direkt personenbezogene Daten, basieren jedoch auf einer einzigartigen Identifizierung Ihres Browsers und Internet-Geräts. Wenn Sie diese Cookies nicht zulassen, werden Sie weniger gezielte Werbung erleben.',
        },
      },
      purposes: [CookieService.marketing],
      cookies: [
        [/^_ga(_.*)?/, '/', '.qossmic.com'],
        [/^_ga(_.*)?/, '/', 'localhost'],
        [/^_gid(_.*)?/, '/', '.qossmic.com'],
        [/^_gid(_.*)?/, '/', 'localhost'],
        [/^__hs(.*)?/, '/', '.qossmic.com'],
        [/^__hs(.*)?/, '/', 'localhost'],
        [/^hubspot(.*)?/, '/', '.qossmic.com'],
        [/^hubspot(.*)?/, '/', 'localhost'],
      ],
      required: false,
      onlyOnce: true,
    },
    {
      name: CookieService.youtube,
      default: false,
      translations: {
        zz: {
          title: 'Cookies für YouTube',
          description:
            'Dieser Cookie ist notwendig damit Inhalte von YouTube abgespielt werden können',
        },
      },
      purposes: [CookieService.youtube],
      required: false,
      onlyOnce: true,
    },
    /*
    {
      // Each service should have a unique (and short) name.
      name: 'matomo',

      // If "default" is set to true, the service will be enabled by default
      // Overwrites global "default" setting.
      // We recommend leaving this to "false" for services that collect
      // personal information.
      default: true,

      // The title of your service as listed in the consent modal.
      title: 'Matomo/Piwik',

      // The purpose(s) of this service. Will be listed on the consent notice.
      // Do not forget to add translations for all purposes you list here.
      purposes: ['analytics'],

      // A list of regex expressions or strings giving the names of
      // cookies set by this service. If the user withdraws consent for a
      // given service, Klaro will then automatically delete all matching
      // cookies.
      cookies: [
        // you can also explicitly provide a path and a domain for
        // a given cookie. This is necessary if you have services that
        // set cookies for a path that is not "/" or a domain that
        // is not the current domain. If you do not set these values
        // properly, the cookie can't be deleted by Klaro
        // (there is no way to access the path or domain of a cookie in JS)
        // Notice that it is not possible to delete cookies that were set
        // on a third-party domain! See the note at mdn:
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-cookie_domain
        [/^_pk_.*$/, '/', 'klaro.kiprotect.com'], //for the production version
        [/^_pk_.*$/, '/', 'localhost'], //for the local version
        'piwik_ignore',
      ],

      // An optional callback function that will be called each time
      // the consent state for the service changes (true=consented). Passes
      // the `service` config as the second parameter as well.
      callback: function (consent, service) {
        // This is an example callback function.
        // To be used in conjunction with Matomo 'requireCookieConsent' Feature, Matomo 3.14.0 or newer
        // For further Information see https://matomo.org/faq/new-to-piwik/how-can-i-still-track-a-visitor-without-cookies-even-if-they-decline-the-cookie-consent/

        // if(consent==true){
        //     _paq.push(['rememberCookieConsentGiven']);
        // } else {
        //     _paq.push(['forgetCookieConsentGiven']);
        // }

        const COOKIE_NAME = 'qossmic_consent'

        // check for cookie-layer cookie
        if (document.cookie.indexOf(COOKIE_NAME) !== -1) {
          $('.consent-button').show()
        }
      },

      // If "required" is set to true, Klaro will not allow this service to
      // be disabled by the user.
      required: false,

      // If "optOut" is set to true, Klaro will load this service even before
      // the user gave explicit consent.
      // We recommend always leaving this "false".
      optOut: false,

      // If "onlyOnce" is set to true, the service will only be executed
      // once regardless how often the user toggles it on and off.
      onlyOnce: true,
    },
     */
  ],
}

class KlaroWrapper {
  _scriptId: string
  _initialized = false

  initialize = (scriptId: string): void => {
    if (this.isInitialized()) {
      return
    }

    this._scriptId = scriptId
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.klaroConfig = klaroConfig
    const head = document.getElementsByTagName('head')[0]
    const klaroScript = document.createElement('script')
    klaroScript.id = scriptId
    klaroScript.setAttribute('type', 'text/javascript')
    klaroScript.setAttribute('src', '/consent/klaro-no-translations.min.js')
    head.appendChild(klaroScript)

    const gtagScript = document.createElement('script')
    gtagScript.setAttribute('type', 'text/plain')
    gtagScript.dataset.name = 'marketing'
    gtagScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_ID}')`
    head.appendChild(gtagScript)
  }

  open = (): void => {
    this._getInstance().show()
  }

  hasConsent = (service: CookieService): boolean => {
    if (this._getManager()) {
      return this._getManager().getConsent(service)
    }

    return false
  }

  setConsent = (service: CookieService, allow: boolean): void => {
    if (this._getManager()) {
      this._getManager().updateConsent(service, allow)
      this._getManager().saveAndApplyConsents()
    }
  }

  _getInstance = () => {
    if (this.isInitialized()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.klaro
    }
  }

  _getManager = () => {
    if (this.isInitialized()) {
      return this._getInstance().getManager()
    }
  }

  isInitialized = (): boolean => {
    if (this._initialized) {
      return true
    }

    const _document = typeof document === 'object' ? document : null

    if (_document === null) {
      return false
    }

    const element = _document.getElementById(this._scriptId)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._initialized = element !== null && typeof window.klaro === 'object'

    return this._initialized
  }
}

export type ConsentState = {
  [CookieService.essential]: boolean
  [CookieService.marketing]: boolean
  [CookieService.youtube]: boolean
}

const klaroInstance = new KlaroWrapper()

const useKlaro = (): [
  KlaroWrapper,
  ConsentState,
  (service: CookieService, state: boolean) => void
] => {
  const [klaro] = useState<KlaroWrapper>(klaroInstance)
  const [consent, saveConsent] = useState<ConsentState>({} as ConsentState)

  const watcher = {
    update: (obj: any, name: string, data: any) => {
      if (name === 'saveConsents') {
        const updatedConsent = data.consents
        saveConsent({ ...(updatedConsent as ConsentState) })
      }
      if (name === 'consents') {
        // saveConsent({ ...(data as ConsentState) })
      }
    },
  }

  useEffect(() => {
    // create the required script tags in the <head>
    klaro.initialize('consentManager')

    // try a few times until klaro is really available to set the state
    const intervalId = setInterval(() => {
      if (klaro.isInitialized() && klaro._getManager()) {
        clearInterval(intervalId)
        const klaroConsent = klaro._getManager().loadConsents()

        saveConsent({ ...klaroConsent })
        // register klaro watcher to get notified if the user changes settings from the klaro overlay
        klaro._getManager().watch(watcher)
      }
    }, 25)

    return () => clearInterval(intervalId)
  }, [])

  const setConsent = (service: CookieService, state: boolean): void => {
    if (!klaro.isInitialized()) {
      return
    }

    const updatedConsent = {
      ...consent,
      [service]: state,
    }

    // only update klaro and state if value has actually changed
    if (JSON.stringify(consent) === JSON.stringify(updatedConsent)) {
      return
    }

    klaro.setConsent(service, state)
    saveConsent(updatedConsent)
  }

  return [klaro, consent, setConsent]
}

export { useKlaro, KlaroWrapper }
